import logoImage from '../assets/img/logo.svg';
import bgImage from '../assets/img/pnwdg-bg.jpg';

function Header() {
    return (
        <header id="center-tile">
            <img src={logoImage} alt="PNW Disc Golf"/>
        </header>
    )
}

export default Header;