import Helmet from 'react-helmet';
import Header from './components/Header';
import Course from './components/Course';
import './App.scss';

const courses = [
  {
    title: 'Evergreen State College',
    holes: 18,
  },
  {
    title: 'St. Martins University',
    holes: 9,
 },
  {
    title: 'Woodland Creek',
    holes: 14,
},
];

function App() {
  return (
      <div>
        <Helmet>
          <title>PNW Disc Golf</title>
          <meta name="description" content="Explore the scenic beauty of the Pacific Northwest through its premier disc golf courses. Dive into guides, reviews, and local events, and discover why the PNW is a disc golfer's paradise. Join the community today!"/>
        </Helmet>
        <Header />
        {/*<ul id="courses">*/}
        {/*  <Course course={courses[0]}>*/}
        {/*  </Course>*/}
        {/*  <Course course={courses[1]}>*/}
        {/*  </Course>*/}
        {/*  <Course course={courses[2]}>*/}
        {/*  </Course>*/}
        {/*</ul>*/}
      </div>
  );
}

export default App;
